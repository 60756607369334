import React, { useEffect, useState } from 'react';
import { useAllThemes } from '../../hooks/useAllThemes.ts';
import { Loading } from '../../../commons/components/Loading';
import { Alert, AlertTitle, Badge, Icon, Menu, MenuButton, MenuItem, MenuList, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useToast } from '@chakra-ui/react';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { assignThemeToPresentation, deleteThemeById, ThemeItem, unassignThemeToPresentation } from '../../service/ThemingService.ts';
import { ThemeT } from '../../model/ThemeT.ts';
import { translate } from '../../../../translate';
import { refreshElementPreview } from '../../../presentation/components/ElementPreview/ElementPreview.tsx';
import { useThemeForPresentation } from '../../hooks/useThemeForPresentation.ts';
import { FiCrosshair } from 'react-icons/fi';
import { extractRestExceptionInfo } from '../../../../apiClient/ApiErrorHandler.ts';

interface Props {
	presentationId: string;
	onEdit: (themeId: ThemeT['id']) => void;
}

const DEFAULT_THEME: ThemeItem = {
	id: -1,
	label: translate('Questiory'),
};

export const ThemeList: React.FC<Props> = ({ presentationId, onEdit }) => {
	const { isPending, data, isError, refetch } = useAllThemes();
	const toast = useToast();
	const [isLoading, setIsLoading] = useState<ThemeT['id'] | undefined>();
	const { data: selectedTheme, refetch: refetchCurrentTheme } = useThemeForPresentation(presentationId);

	useEffect(() => {
		return () => {
			refreshElementPreview();
		};
	}, []);

	const handleAssign = (themeId: ThemeT['id']) => async () => {
		try {
			setIsLoading(themeId);
			if (themeId && themeId > 0) {
				await assignThemeToPresentation(themeId, presentationId);
			} else {
				await unassignThemeToPresentation(presentationId);
			}
			await refetchCurrentTheme(); //Refresh selected theme
		} catch (e) {
			console.error('Error while trying to assign theme to presentation', e);
		} finally {
			setIsLoading(undefined);
		}
	};

	const handleOnEdit = (themeId: ThemeT['id']) => () => {
		onEdit(themeId);
	};

	const handleDelete = (themeId: ThemeT['id']) => async () => {
		try {
			setIsLoading(themeId);
			await deleteThemeById(themeId);
			await refetch();
		} catch (e) {
			const error = extractRestExceptionInfo(e);
			if (error) {
				toast({
					title: 'Error',
					description: error.message,
					status: 'error',
					duration: 4000,
					position: 'top',
					isClosable: true,
				});
			}
		} finally {
			setIsLoading(undefined);
		}
	};

	if (isPending) {
		return <Loading />;
	}

	if (isError) {
		return (
			<Alert status='error'>
				<AlertTitle>{translate('Error fetching themes')}</AlertTitle>
			</Alert>
		);
	}

	if (!data) {
		return (
			<>
				<Text>{translate('No themes yet')}</Text>
				<Text>{translate('Create a new theme to start')}</Text>
			</>
		);
	}

	const TableThemeRow = ({ theme, isSelected, isDisabled }: { theme: ThemeItem; isSelected: boolean; isDisabled: boolean }) => {
		return (
			<>
				<Tr key={theme.id} _hover={{ cursor: 'default' }}>
					<Td _hover={{ cursor: !isDisabled ? 'pointer' : 'default' }} onClick={!isDisabled ? handleOnEdit(theme.id) : undefined}>
						{theme.label}
					</Td>
					<Td _hover={{ cursor: !isDisabled ? 'pointer' : 'default' }} onClick={!isDisabled ? handleOnEdit(theme.id) : undefined}>
						{isSelected ? <Badge colorScheme='green'>Assigned</Badge> : undefined}
					</Td>
					<Td>
						{isLoading === theme.id ? (
							<Spinner />
						) : (
							<Menu variant='simple'>
								<MenuButton>
									<Icon as={BiDotsHorizontalRounded} fontSize='xl' />
								</MenuButton>
								<MenuList fontSize='xl'>
									<MenuItem onClick={handleAssign(theme.id)} icon={<FiCrosshair />} isDisabled={isSelected}>
										{translate('Assign')}
									</MenuItem>
									<MenuItem onClick={handleOnEdit(theme.id)} icon={<AiOutlineEdit />} isDisabled={theme.id === DEFAULT_THEME.id}>
										{translate('Edit')}
									</MenuItem>
									<MenuItem onClick={handleDelete(theme.id)} icon={<AiOutlineDelete />} color='red' isDisabled={theme.id === DEFAULT_THEME.id}>
										{translate('Delete')}
									</MenuItem>
								</MenuList>
							</Menu>
						)}
					</Td>
				</Tr>
			</>
		);
	};

	return (
		<TableContainer width='90%' maxH='45vh' overflowY='scroll'>
			<Table>
				<Thead sx={{ position: 'sticky', top: 0 }}>
					<Tr>
						<Th>{translate('Title')}</Th>
						<Th></Th>
						<Th></Th>
					</Tr>
				</Thead>
				<Tbody>
					{data.map((value) => (
						<TableThemeRow key={value.id} theme={value} isSelected={value.id === selectedTheme?.id} isDisabled={false} />
					))}
					<TableThemeRow key={DEFAULT_THEME.id} theme={DEFAULT_THEME} isSelected={!selectedTheme || selectedTheme.id === DEFAULT_THEME.id} isDisabled={true} />
				</Tbody>
			</Table>
		</TableContainer>
	);
};
