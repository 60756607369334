import {
	Button,
	Flex,
	Heading,
	Icon,
	ListItem,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	UnorderedList,
	useDisclosure,
} from '@chakra-ui/react';
import { FiExternalLink } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useInvalidatePresentationQuery } from '../../../presentation/hooks/usePresentation.ts';
import { deleteInteraction } from '../../../interaction/service/InteractionService.ts';
import { getClienAppSlideUrl } from '../../../presentation/service/LinkService.ts';
import { deleteVisualization } from '../../../visualization/service/VisualizationService.ts';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import React from 'react';
import { MdRemove } from 'react-icons/md';
import { deleteStaticContent } from '../../../staticContent/service/StaticContentService.ts';
import { InteractionT } from '../../../../types/Interaction.ts';
import { VisualizationT } from '../../../../types/Visualization.ts';
import { StaticContentT } from '../../../../types/StaticContent.ts';
import { RiListSettingsLine } from 'react-icons/ri';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { translate } from '../../../../translate';
import { useVisualizationsByInteraction } from '../../../interaction/hooks/useVisualizationsByInteraction.ts';
import { Loading } from '../Loading';

interface Props {
	presentationId: string;
	element: InteractionT | VisualizationT | StaticContentT;
	elementType: ElementTypeT;
}

const NAME_MAPPING: Record<ElementTypeT, string> = {
	[ElementTypeT.INTERACTION]: translate('interaction'),
	[ElementTypeT.VISUALIZATION]: translate('visualization'),
	[ElementTypeT.STATIC_CONTENT]: translate('Content').toLowerCase(),
};

export const ElementMoreMenu: React.FC<Props> = ({ presentationId, element, elementType }) => {
	const navigate = useNavigate();
	const invalidatePresentationQuery = useInvalidatePresentationQuery(presentationId);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { data: visualizationsForInteraction, isLoading } = useVisualizationsByInteraction(presentationId, element.id, elementType === ElementTypeT.INTERACTION && isOpen);

	const handleDelete = () => {
		handleOnDeleteElement();
		onClose();
	};

	const handleOnDeleteElement = async () => {
		if (elementType === ElementTypeT.INTERACTION) {
			await deleteInteraction(presentationId, element.id);
		} else if (elementType === ElementTypeT.VISUALIZATION) {
			await deleteVisualization(presentationId, element.id);
		} else if (elementType === ElementTypeT.STATIC_CONTENT) {
			await deleteStaticContent(presentationId, element.id);
		}
		invalidatePresentationQuery();
		navigate(`/presentations/${presentationId}`);
	};

	const handleViewInBrowserClick = () => {
		const url = getClienAppSlideUrl(presentationId, element.slide.id);
		const finalUrl = element.slide.hidden ? `${url}/preview` : url;
		window.open(finalUrl, '_blank');
	};

	const handleManageInteractionEntries = () => {
		navigate(`/presentations/${presentationId}/interactions/${element.id}/manage-entries`);
	};

	return (
		<>
			<Flex>
				<Menu variant='simple'>
					<MenuButton>
						<Icon as={BiDotsHorizontalRounded} fontSize='xl' />
					</MenuButton>
					<MenuList fontSize={18}>
						<MenuItem icon={<FiExternalLink />} onClick={handleViewInBrowserClick}>
							{translate('View slide')}
						</MenuItem>
						{elementType === ElementTypeT.INTERACTION ? (
							<MenuItem icon={<RiListSettingsLine />} onClick={handleManageInteractionEntries}>
								{translate('Manage entries for interaction')}
							</MenuItem>
						) : null}
						<MenuItem icon={<MdRemove />} color='red' onClick={onOpen}>
							{translate('Delete')} {NAME_MAPPING[elementType]}
						</MenuItem>
					</MenuList>
				</Menu>
			</Flex>
			<Modal isOpen={isOpen} size='xl' isCentered onClose={onClose}>
				<ModalOverlay />
				<ModalContent p={8}>
					<ModalCloseButton />
					<ModalHeader>
						<Text fontSize='2xl' textAlign='center'>
							{`${translate('You are about to delete')} ${NAME_MAPPING[elementType]}: ${element.title}`}
						</Text>
					</ModalHeader>
					<ModalBody m={0} p={0} textAlign='center' as={Flex} justifyContent='center' alignItems='center' flexDirection='column' gap={4}>
						<Heading m={0} pt={0} fontSize='sm'>
							{translate('Are you sure?')}
						</Heading>
						{isLoading ? <Loading /> : null}
						{visualizationsForInteraction && visualizationsForInteraction?.length > 0 ? <Text>{translate('The following associated visualizations will be removed')}</Text> : null}
						<UnorderedList maxH='20' overflowX='scroll' px={4}>
							{visualizationsForInteraction?.map((value) => (
								<ListItem key={value.id} my={2} fontWeight='bold'>
									{value.title}
								</ListItem>
							))}
						</UnorderedList>
					</ModalBody>
					<ModalFooter my={2} justifyContent='space-evenly'>
						<Button onClick={handleDelete} variant='submit'>
							{translate('Yes')}
						</Button>
						<Button onClick={onClose} variant='submit'>
							{translate('No')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};
